/**
 * Created by mac on 12/8/20
 */

cleverapps.styles.COLORS = cleverapps.overrideColors(cleverapps.styles.COLORS, {
    GUIDEWINDOW_TEXT_COLOR: new cc.Color(173, 85, 14, 255),
    TITLE_COLOR_YELLOW: new cc.Color(134, 56, 10, 255),
    TITLE_SHADOW: new cc.Color(255, 251, 126, 255)
});

cleverapps.overrideFonts(cleverapps.styles.DECORATORS, {
    WHITE_TEXT_STROKE: {
        color: cleverapps.styles.COLORS.BLACK,
        size: 2
    },

    STARTQUESTWINDOW_DARK_SHADOW: {
        color: new cc.Color(255, 249, 221, 255),
        direction: cc.size(0, -3),
        blur: 0
    },

    GUIDEWINDOW_TEXT_SHADOW: {
        color: new cc.Color(255, 243, 236, 255),
        direction: cc.size(0, -2),
        blur: 0
    },

    FEAST_MISSION_STROKE: {
        color: new cc.Color(185, 57, 0, 255),
        size: 4
    },

    FEAST_MISSION_SHADOW: {
        color: new cc.Color(0, 0, 0, 128),
        direction: cc.size(0, -4),
        blur: 5
    },

    MISSION_OFFER_SOFT_STROKE: {
        color: new cc.Color(255, 144, 0, 255),
        size: 3
    },

    CHESTTIMEMISSIONWINDOW_TITLE_TEXT_STROKE: {
        color: new cc.Color(4, 72, 246, 255),
        size: 2
    },

    CHESTTIMEMISSIONWINDOW_TITLE_TEXT_SHADOW: {
        color: new cc.Color(0, 120, 255, 155),
        direction: cc.size(0, -1),
        blur: 3
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    PACKWINDOW_LABEL_TEXT: {
        size: 42,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    QUEST_PROGRESS_TEXT: {
        name: "nostroke",
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR
    },

    WAND_PROGRESS_TEXT: {
        size: 26
    },

    LEVELUPWINDOW_TITLE_TEXT: {
        size: 96,
        color: new cc.Color(241, 233, 19, 255),
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    LEVELUPWINDOW_VALUE_TEXT: {
        size: 200,
        color: new cc.Color(193, 143, 55),
        stroke: undefined,
        shadow: {
            color: new cc.Color(255, 243, 236),
            direction: cc.size(0, -2),
            blur: 0
        }
    },

    LEVELUPWINDOW_REWARD_TEXT: {
        name: "big_digits",
        size: 80,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    STARTQUESTWINDOW_TITLE_TEXT: {
        size: 90,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    STARTQUESTWINDOW_TILE_TASK_TEXT: {
        size: 48,
        color: new cc.Color(146, 91, 44, 255),
        stroke: undefined,
        shadow: cleverapps.styles.DECORATORS.STARTQUESTWINDOW_DARK_SHADOW
    },

    INFO_TIMER_TEXT: {
        name: "nostroke",
        size: 26,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR
    },

    FEAST_MISSION_MAIN_TITLE_TEXT: {
        size: 90,
        color: new cc.Color(255, 255, 0, 255),
        stroke: cleverapps.styles.DECORATORS.FEAST_MISSION_STROKE,
        shadow: cleverapps.styles.DECORATORS.FEAST_MISSION_SHADOW
    },

    FEAST_MISSION_TIMER_TEXT: {
        size: 60,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    PASS_TITLE_TEXT: {
        size: 42,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR
    },

    PASS_TIMER_TEXT: {
        name: "nostroke",
        size: 45,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR
    },

    PASS_PROGRESS_IMAGE_TEXT: {
        size: 40,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    PASS_PROGRESS_POINT: {
        name: "nostroke",
        size: 40,
        color: cleverapps.styles.COLORS.COLOR_WINDOW_TEXT
    },

    PASS_TICKET_TEXT: {
        size: 35,
        color: new cc.Color(247, 231, 25),
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    PASS_TICKET_SMALL_TEXT: {
        size: 30,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    PASS_REWARD_TEXT: {
        size: 56,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    MISSION_OFFER_REWARD: {
        name: "big_digits",
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    RUINS_TIMER: {
        name: "nostroke",
        size: 35,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR,
        stroke: undefined,
        shadow: undefined
    },

    SEASONAL_TIMER_TEXT: {
        name: "nostroke",
        size: 45,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR
    },

    CHESTTIMEMISSIONWINDOW_TITLE_TEXT: {
        size: 100,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.CHESTTIMEMISSIONWINDOW_TITLE_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.CHESTTIMEMISSIONWINDOW_TITLE_TEXT_SHADOW
    },

    BOOSTTIMEMISSIONWINDOW_REWARD_TEXT: {
        size: 50
    },

    COMPONENT_TIMER_TEXT: {
        size: 22,
        color: cleverapps.styles.COLORS.WHITE
    },

    FOG_BLOCKER_TEXT: {
        size: 36,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    CARAVAN_REWARDS_BLOCK_TEXT: {
        name: "nostroke",
        size: 32,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR
    },

    ENERGY_OFFER: {
        size: 85,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.IMAGE_FONT_STROKE
    }
});

cleverapps.overrideStyles(cleverapps.styles.GameScene, {
    sound: {
        delay: 1.5,
        fadeIn: 1
    }
});

cleverapps.overrideStyles(cleverapps.styles.FamilyLayout, {
    title: {
        x: { align: "center", dx: -1 },
        y: { align: "top", dy: -8 },

        fitToLayoutWidth: true,
        padding: {
            x: -7,
            y: 4
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.FogTileView, {
    moneyFogUnits: {
        "worker_4": {
            color: new cc.Color(73, 69, 52, 255),
            opacity: 63
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.InfoView, {
    minHeight: 120,
    minWidth: 130,
    minWidthWithDecorators: 240,

    padding: {
        x: 6,
        top: 12,
        bottom: 22
    },

    pointer: {
        y: -1
    },

    decorators: [
        {
            image: bundles.merge.frames.info_liana1,
            x: { align: "left", dx: -5 },
            y: { align: "top", dy: 10 }
        },
        {
            image: bundles.merge.frames.info_liana2,
            x: { align: "right", dx: 6 },
            y: { align: "bottom", dy: -2 }
        }
    ],

    title: {
        width: 170
    },

    bar: {
        width: 196,
        height: 42,
        type: ScaledProgressBar.Types.blue
    },

    button: {
        width: 220,
        height: 70
    },

    guide: {
        x: { align: "right", dx: 25 },
        y: { align: "top", dy: 25 }
    }
});

cleverapps.overrideStyles(cleverapps.styles.IngredientsInfoView, {
    padding: {
        x: 25,
        top: 12,
        bottom: 22
    }
});

cleverapps.overrideStyles(cleverapps.styles.FieldInfoView, {
    padding: {
        x: 25,
        top: 12,
        bottom: 22
    }
});

cleverapps.overrideStyles(cleverapps.styles.MineableView, {
    progress: {
        width: 92,
        height: 19,

        upY: { align: "bottom", dy: 55 }
    }
});

cleverapps.overrideStyles(cleverapps.styles.ComponentTimer, {
    timer: {
        width: 160
    }
});

cleverapps.overrideStyles(cleverapps.styles.PrizeView, {
    "default": {
        width: 106,
        height: 97,

        padding: {
            x: 18,
            y: 14
        },

        pointer: {
            dy: 2
        },

        animation: {
            x: { align: "center" },
            y: { align: "center", dy: 0 }
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.GiftContentView, {
    width: 640,
    minHeight: 160
});

cleverapps.overrideStyles(cleverapps.styles.FogBlockView, {
    openerWindow: {
        button: {
            y: { align: "bottom", dy: -60 },
            width: 200,
            height: 60,
            type: cleverapps.styles.UI.Button.Images.small_button_green
        }

    },

    opener: {
        height: 65,
        y: { align: "top", dy: 50 },

        text: {
            width: 130,
            x: { align: "center", dx: 0 },
            y: { align: "center", dy: 1 }
        },

        icon: {
            y: { align: "center", dy: 0 }
        }
    },

    blocker: {
        content: {
            width: 54,
            height: 54
        },

        position: {
            x: { align: "center" },
            y: { align: "center", dy: 80 }
        },
        text: {
            x: { align: "center", dx: -10 },
            y: { align: "center", dy: 13 }
        },
        icon: {
            x: { align: "center", dx: -10 },
            y: { align: "center", dy: 12 }
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.UnitsShopTile, {
    height: 790,

    icon: {
        x: { align: "center" },
        y: { align: "center", dy: 110 },
        scale: 1.3
    },

    blockIcon: {
        x: { align: "center" },
        y: { align: "center", dy: 90 }
    },

    title: {
        x: { align: "center", dx: 7 },
        y: { align: "center", dy: -75 },

        width: 360,
        height: 100,

        amount: {
            x: { align: "center" },
            y: { align: "center", dy: 8 }
        }
    },

    description: {
        x: { align: "center" },
        y: { align: "center", dy: -175 },
        width: 350,
        height: 210
    },

    button: {
        x: { align: "center" },
        y: { align: "center", dy: -285 },
        width: 300,
        height: 106
    },

    leftText: {
        x: { align: "center" },
        y: { align: "center", dy: 240 },
        width: 350
    },

    guideButton: {
        x: { align: "center", dx: -150 },
        y: { align: "center", dy: 240 }
    },

    attention: {
        x: { align: "center", dx: 205 },
        y: { align: "top", dy: 25 }
    },

    decors: [
        cleverapps.styles.Decorators["shop_marquise_lives"]
    ]
});

cleverapps.overrideStyles(cleverapps.styles.BuyFogWindow, {
    island: {
        height: 630,
        y: { align: "center", dy: 210 }
    }
});

cleverapps.overrideStyles(cleverapps.styles.PixelView, {
    collect: {
        x: { align: "center", dx: 0 },
        y: { align: "center", dy: -10 },
        delay: 300
    },
    timeScale: {
        base: 0.7,
        random: 0.3
    }
});

cleverapps.overrideStyles(cleverapps.styles.WorkerView, {
    timer: {
        x: { align: "center", dx: -10 },
        y: { align: "bottom", dy: 0 }
    },

    miningAnimation: {
        x: { align: "center", dx: -12, leftDx: 8 },
        y: { align: "bottom", dy: 0 }
    },

    buildingAnimation: {
        x: { align: "center", dx: 0 },
        y: { align: "bottom", dy: 0 }
    }
});

cleverapps.overrideStyles(cleverapps.styles.CastleGuideWindow, {
    firstStage: {
        foreground: false,

        components: [
            {
                x: -107,
                y: -166,
                scale: 0.8
            },
            {
                x: -4,
                y: -236,
                scale: 0.8
            },
            {
                x: 105,
                y: -166,
                scale: 0.8
            }
        ]
    },

    secondStage: {
        container: {
            x: { align: "center" },
            y: { align: "center", dy: -30 }
        },

        castle: {
            x: 0,
            y: 0,
            scale: 0.8
        },

        icon: {
            x: { align: "left", dx: 10 },
            y: { align: "center", dy: 0 },
            scale: 0.22
        },

        components: [
            {
                x: -94,
                y: 218,
                scale: 0.6
            },
            {
                x: 96,
                y: 200,
                scale: 0.7
            }
        ]
    },

    thirdStage: {
        container: {
            x: { align: "center" },
            y: { align: "center", dy: -10 }
        },

        castle: {
            x: 0,
            y: 0,
            scale: 0.9
        },

        light: {
            x: 0,
            y: 50
        },

        crystals: {
            x: 0,
            y: 50
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.CrystalGuideWindow, {
    secondStage: {
        foreground: {
            x: -15,
            y: 20,
            scale: 1.3
        }
    },

    thirdStage: {

        highlight: {
            dx: -5,
            dy: 20
        },

        units: [
            {
                x: 1,
                y: -254,
                dx: -5,
                dy: 10,
                scale: 1.1
            },
            {
                x: -109,
                y: -196,
                dx: 0,
                dy: 5,
                scale: 1.3
            },
            {
                x: 110,
                y: -176,
                dx: 0,
                dy: 5,
                scale: 1.3
            },
            {
                x: -8,
                y: -30,
                dx: 10,
                dy: 15,
                scale: 1.4
            }
        ]
    }
});

cleverapps.overrideStyles(cleverapps.styles.InstantWorkerView, {
    progress: {
        type: ScaledProgressBar.Types.blue_small,
        width: 90,
        x: 0,
        y: -50
    },

    timer: {
        x: { align: "center", dx: 0 },
        y: { align: "center", dy: -29 }
    }
});

cleverapps.overrideStyles(cleverapps.styles.InstantWorkerGuideWindow, {
    firstStage: {
        unit: {
            x: 0,
            y: 10,
            scale: 1.3
        },

        components: [
            {
                x: -141,
                y: -155,
                scale: 1.1
            },
            {
                x: -32,
                y: -219,
                scale: 1.1
            },
            {
                x: 83,
                y: -147,
                scale: 1.1
            }
        ]
    },

    secondStage: {
        unit: {
            scale: 1.5
        },

        foreground: {
            x: -12,
            y: 19,
            scale: 1.3
        }
    },

    thirdStage: {
        rays: {
            x: -25,
            y: -120
        },

        unit: {
            x: { align: "center", dx: 10 },
            y: { align: "center", dx: 20 },
            scale: 1.4
        },

        building: {
            x: 125,
            y: 110,
            scale: 1.4
        },

        attention: {
            x: { align: "center" },
            y: { align: "center" },
            scale: 0.7
        },

        hand: {
            x: 45,
            y: -210,
            scale: 0.7
        },

        arrow: {
            x: 20,
            y: 30
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.OpenChestWindow, {
    watchButton: {
        unit: {
            x: { align: "center", dx: 97 },
            y: { align: "center", dy: 0 },
            scale: 1.3
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.BonusWorkerWindow, {
    animation: {
        leftWorkers: {
            reverse: false,
            margin: 0
        }
    },
    person: {
        scale: 1,
        x: { align: "center", dx: -680 },
        y: { align: "bottom", dy: -80 }
    }
});

cleverapps.overrideStyles(cleverapps.styles.HeroIcon, {
    icon: {
        x: { align: "center", dx: 0 },
        y: { align: "bottom", dy: 6 }
    },

    selection: {
        x: { align: "center" },
        y: { align: "center", dy: -1 }
    }
});

cleverapps.overrideStyles(cleverapps.styles.UpgradableView, {
    level: {
        x: { align: "left", dx: -28 },
        text: {
            y: { align: "center", dy: 1 },
            maxWidth: 36
        }
    },

    progress: {
        type: ScaledProgressBar.Types.blue,
        width: 180,
        height: 36,

        text: {
            x: { align: "center" },
            y: { align: "center", dy: 0 }
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.UnitLibraryIconBg, {
    icon: {
        width: 230,
        height: 205
    }
});

cleverapps.overrideStyles(cleverapps.styles.UnitLibraryIcon, {
    producer: {
        y: { align: "bottom", dy: 35 }
    }
});

cleverapps.overrideStyles(cleverapps.styles.HeroDetailsLayout, {
    grid: {
        x: { align: "left", dx: 3 },
        y: { align: "center", dy: -40 }
    },

    foreground: {
        padding: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0
        }
    },

    buttonInfo: {
        x: { align: "right", dx: -20 },
        y: { align: "top", dy: -15 }
    }
});

cleverapps.overrideStyles(cleverapps.styles.BuyIngredientsWindow, {
    tile: {
        amountBackground: {
            width: 250,
            height: 100,
            x: { align: "center", dx: 0 },
            y: { align: "center", dy: -72 }
        },

        amountText: {
            x: { align: "center" },
            y: { align: "center", dy: -68 }
        },

        title: {
            x: { align: "center" },
            y: { align: "center", dy: -162 }
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.UnitView, {
    mergeAnimation: {
        x: 0,
        y: 20
    },

    didMerge: {
        delay: 0.3
    }
});

cleverapps.overrideStyles(cleverapps.styles.StartQuestWindow, {
    width: 1200,

    margin: 20,

    tile: {
        width: 1000,
        height: 600,

        animation: {
            x: { align: "center", dx: 0 },
            y: { align: "center", dy: 0 }
        },

        icon: {
            x: { align: "center", dx: -220 },
            y: { align: "center", dy: 5 },
            scale: 0.9
        },

        task: {
            x: { align: "center", dx: 110 },
            y: { align: "center", dy: -25 },
            width: 370,
            height: 200
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.FinishQuestWindow, {
    person: {
        scale: 1.4,

        first: {
            x: { align: "center", dx: -760 },
            y: { align: "bottom", dy: 0 }
        },

        second: {
            x: { align: "center", dx: 850 },
            y: { align: "bottom", dy: 0 }
        }
    },

    checkmark: {
        x: { align: "center", dx: 135 },
        y: { align: "center", dy: 125 }
    }
});

cleverapps.overrideStyles(cleverapps.styles.PackWindow, {
    button: {
        delay: 1
    },

    animation: {
        "default": {
            idleDelay: 0,
            dx: 0,
            dy: -30
        },

        "starterPack0": {
            idleAnimation: "0_idle",
            introAnimation: "0_start"
        },

        "starterPack": {
            idleAnimation: "1_idle",
            introAnimation: "1_start"
        },

        "starterPack2": {
            idleAnimation: "2_idle",
            introAnimation: "2_start"
        }
    },

    rewards: {
        delay: 1.6,
        duration: 0.675,
        fitTo: {
            width: 158
        },

        positions: {
            "default": undefined,

            "starterPack0": {
                "hard": {
                    x: 138,
                    y: -73
                },
                "energy": {
                    x: -134,
                    y: -74
                }
            },

            "starterPack": {
                "hard": {
                    x: 107,
                    y: -88
                },
                "energy": {
                    x: 118,
                    y: 196
                },
                "wands": {
                    x: -132,
                    y: 195
                },
                "worker": {
                    x: -105,
                    y: -88
                }
            },

            "starterPack2": {
                "hard": {
                    x: 124,
                    y: 169
                },
                "energy": {
                    x: 192,
                    y: -83
                },
                "wands": {
                    x: -128,
                    y: 171
                },
                "soft": {
                    x: 2,
                    y: -54
                },
                "worker": {
                    x: -186,
                    y: -80
                }
            }
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.UnitsShopCart, {
    topIcon: {
        x: { align: "center", dx: -2 },
        y: { align: "center", dy: -12 }
    },

    amountBlock: {
        amountText: {
            x: { align: "center", dx: 1 },
            y: { align: "center", dy: 2 },
            maxWidth: 40
        }
    },

    item: {
        firstItem: {
            oneItem: {
                x: { align: "center", dx: -1 },
                y: { align: "bottom", dy: 88 }
            },
            twoItems: {
                x: { align: "center", dx: -15 },
                y: { align: "bottom", dy: 85 }
            }
        },

        secondItem: {
            x: { align: "center", dx: 15 },
            y: { align: "bottom", dy: 90 }
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.OrdersWindow, {
    grid: {
        y: { align: "center" },
        height: 835,
        margin: {
            x: 20,
            y: 20
        },
        padding: {
            x: 30,
            y: 30
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.OrderIcon, {
    pin: {
        x: { align: "left" },
        y: { align: "top", dy: 3 }
    },

    button: {
        y: { align: "center", dy: -62 },
        height: 82
    }
});

cleverapps.overrideStyles(cleverapps.styles.IngredientOrderIcon, {
    icon: {
        scale: 1.7
    }
});

cleverapps.overrideStyles(cleverapps.styles.UnitOnCell, {
    ground: {
        x: 6,
        y: -27
    }
});

cleverapps.overrideStyles(cleverapps.styles.LockedComponentView, {
    timer: {
        x: { align: "center", dx: 0 }
    }
});

cleverapps.overrideStyles(cleverapps.styles.MissionOfferWindow[Offers.TYPES.LIVESFEAST], {
    height: 900,

    offer: {
        y: { align: "center", dy: 250 }
    },

    button: {
        x: { align: "center", dx: 0 },
        y: { align: "bottom", dy: 40 }
    },

    person: { role: "worker", emotion: "happy" },

    rewards: {
        energy: {
            x: { align: "center", dx: -490 },
            y: { align: "center", dy: -105 },
            width: 226,
            rotation: 5
        },

        worker: {
            x: { align: "center", dx: 529 },
            y: { align: "center", dy: -102 },
            width: 226,
            rotation: -5
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.MissionOfferWindow[Offers.TYPES.SOFTFEAST], {
    height: 900,

    offer: {
        x: { align: "center", dx: 0 },
        y: { align: "center", dy: 80 }
    },

    person: { role: "worker", emotion: "happy" },

    rewards: {
        energy: {
            x: { align: "center", dx: -550 },
            y: { align: "center", dy: -90 },
            width: 226,
            rotation: 0
        },

        soft: {
            x: { align: "center", dx: 535 },
            y: { align: "center", dy: -90 },
            width: 226,
            rotation: 0
        }
    },

    button: {
        x: { align: "center", dx: -5 },
        y: { align: "bottom", dy: 10 }
    }
});

cleverapps.overrideStyles(cleverapps.styles.MissionOfferWindow[Offers.TYPES.BUILDPASS], {
    height: 640,
    width: 1400,

    badges: [
        {
            x: { align: "right", dx: 46 },
            y: { align: "top", dy: -13 },
            width: 125,
            rotation: 10
        }
    ],

    rewards: {
        unit: {
            bppointsstar: {
                x: { align: "center", dx: -550 },
                y: { align: "center", dy: -220 },
                width: 540,
                rotation: 0
            },
            crystal: {
                x: { align: "center", dx: 540 },
                y: { align: "center", dy: -220 },
                width: 540,
                rotation: 0
            }
        }
    },

    button: {
        y: { align: "bottom", dy: -160 }
    }
});

cleverapps.overrideStyles(cleverapps.styles.MissionOfferWindow[Offers.TYPES.SALEPASS], {
    height: 640,
    width: 1200,

    offer: {
        x: { align: "center", dx: 0 },
        y: { align: "center", dy: 50 }
    },

    badges: [
        {
            x: { align: "right", dx: -150 },
            y: { align: "top", dy: 38 },
            width: 125,
            rotation: 10
        }
    ],

    rewards: {
        font: cleverapps.styles.FONTS.MISSION_OFFER_REWARD2,
        unit: {
            bppointscrystal: {
                x: { align: "center", dx: -545 },
                y: { align: "center", dy: -174 },
                width: 200,
                font: cleverapps.styles.FONTS.MISSION_OFFER_SALE_REWARD,
                rotation: 0
            }
        },

        soft: {
            x: { align: "center", dx: 535 },
            y: { align: "center", dy: -174 },
            width: 200,
            font: cleverapps.styles.FONTS.MISSION_OFFER_SALE_REWARD,
            rotation: 0
        }
    },

    person: { role: "worker", emotion: "happy" },

    button: {
        x: { align: "center", dx: -9 },
        y: { align: "bottom", dy: -160 }
    }
});

cleverapps.overrideStyles(cleverapps.styles.MissionOfferWindow[Offers.TYPES.KRAKENFEAST], {
    width: 1600,
    height: 850,

    offer: {
        x: { align: "center", dx: 0 },
        y: { align: "center", dy: 80 }
    },

    rewards: {
        font: cleverapps.styles.FONTS.MISSION_OFFER_REWARD
    },

    button: {
        width: 300,
        height: 125,
        x: { align: "center", dx: -5 },
        y: { align: "bottom", dy: 70 }
    },

    footNote: {
        x: { align: "center", dx: 0 },
        y: { align: "bottom", dy: -50 }
    }
});

cleverapps.overrideStyles(cleverapps.styles.FeastMissionWindow, {
    margin: 50,

    table: {
        x: { align: "center", dx: -1 },
        y: { align: "center", dy: -116 },
        width: 580,
        height: 670,
        padding: 10,
        innerPadding: 12,
        margin: 5
    },

    mainTitle: {
        x: { align: "center", dx: 0 },
        y: { align: "center", dy: 395 },
        width: 700,
        height: 140
    },

    helpButton: {
        x: { align: "center", dx: -425 },
        y: { align: "center", dy: 390 }
    },

    timer: {
        x: { align: "center", dx: 0 },
        y: { align: "center", dy: 273 }
    },

    finishTitle: {
        x: { align: "center", dx: 0 },
        y: { align: "center", dy: 273 },
        width: 385
    },

    offer: {
        x: { align: "center", dx: 830 },
        y: { align: "center", dy: -440 }
    }
});

cleverapps.overrideStyles(cleverapps.styles.SoftFeastMissionWindow, {
    coin1: {
        x: { align: "center", dx: -434 },
        y: { align: "center", dy: -449 }
    },

    coin2: {
        x: { align: "center", dx: 448 },
        y: { align: "center", dy: -470 }
    }
});

cleverapps.overrideStyles(cleverapps.styles.KrakenFeastMissionWindow, {
    offerButton: undefined,

    offer: {
        x: { align: "center", dx: 830 },
        y: { align: "center", dy: -500 }
    },

    person: {
        left: {
            role: "worker",
            emotion: "happy"
        },
        right: undefined
    }
});

cleverapps.overrideStyles(cleverapps.styles.BarrelAdsWindow, {
    item: {
        iconNode: {
            x: { align: "center", dx: -5 },
            shining: false,
            noWrap: true
        },

        strip: {
            width: 305,
            x: { align: "center", dx: 0 },
            y: { align: "bottom", dy: 120 },

            amount: {
                x: { align: "center" },
                y: { align: "center", dy: 7 }
            }
        },

        title: {
            maxWidth: 300,
            y: { align: "bottom", dy: 47 }
        },

        button: {
            width: 300,
            height: 110
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.SalePassWindow, {
    height: 1080,
    width: 1800,

    "tickets": {
        x: { align: "left", dx: 68 },
        y: { align: "center", dy: -118 }
    },

    "header": {
        x: { align: "center", dx: -10 },
        y: { align: "top", dy: -112 }
    },

    "progress": {
        x: { align: "right", dx: -26 },
        y: { align: "center", dy: -192 }
    }
});

cleverapps.overrideStyles(cleverapps.styles.BuildPassWindow, {
    height: 1080,
    width: 1800,

    "tickets": {
        x: { align: "left", dx: 68 },
        y: { align: "center", dy: -118 }
    },

    "header": {
        x: { align: "center", dx: 0 },
        y: { align: "top", dy: -90 }

    },

    "progress": {
        x: { align: "right", dx: -26 },
        y: { align: "center", dy: -192 }
    }
});

cleverapps.overrideStyles(cleverapps.styles.PassHeader, {
    height: 121,
    timer: {
        height: 55,
        y: { align: "center", dy: 20 },
        tooltip: true,

        countdown: {
            x: { align: "center", dx: 15 },
            y: { align: "center", dy: 0 }
        },

        icon: {
            x: { align: "left", dx: -25 },
            y: { align: "center", dy: -2 }
        }
    },

    decor: {
        x: { align: "center", dx: -585 },
        y: { align: "center", dy: 32 }
    },

    pack: {
        x: { align: "right", dx: 15 },
        y: { align: "center", dy: -13 }
    },
    
    amount: {
        x: { align: "center", dx: 0 },
        y: { align: "center", dy: -88 }
    }

});

cleverapps.overrideStyles(cleverapps.styles.PassHeaderPack, {
    badge: {
        x: { align: "right", dx: 208 },
        y: { align: "center", dy: -36 }
    }
});

cleverapps.overrideStyles(cleverapps.styles.PassHeaderPack[Offers.TYPES.SALEPASS], {
    badge: {
        x: { align: "right", dx: 177 },
        y: { align: "center", dy: -47 }
    }
});

cleverapps.overrideStyles(cleverapps.styles.PassHeaderPack[Offers.TYPES.CLPASS], {
    badge: {
        x: { align: "right", dx: 55 },
        y: { align: "center", dy: -60 }
    }
});

cleverapps.overrideStyles(cleverapps.styles.PassPackBadge, {
    text: {
        x: { align: "center", dx: 35 },
        y: { align: "center", dy: 5 },
        rotation: -15,
        width: 120
    }
});

cleverapps.overrideStyles(cleverapps.styles.PassProgress, {
    height: 700,
    paddingX: 206,

    scroll: {
        x: { align: "center", dx: -55 },
        y: { align: "center", dy: 60 }
    },

    progressBar: {
        x: { align: "left", dx: 0 },
        y: { align: "center", dy: 4 },

        height: 37
    },

    line: {
        first: {
            y: { align: "center", dy: 190 }
        },
        second: {
            y: { align: "center", dy: -190 }
        },
        single: {
            x: { align: "right", dx: -43 },
            y: { align: "center", dy: 74 }
        },
        height: 713,
        scale9: cleverapps.UI.Scale9Rect.TwoPixelXY
    },

    tasks: {
        premiumReward: {
            y: { align: "center", dy: 190 }
        },

        reward: {
            y: { align: "center", dy: -190 }
        },

        bgs: {
            width: 240,
            height: 260
        },

        completedLevelIcon: {
            x: { align: "center", dx: 1 },
            y: { align: "center", dy: 2 }
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.PassRewardIcon, {
    shining: {
        x: { align: "center", dx: -3 },
        y: { align: "center", dy: -2 }
    },

    claimButton: {
        width: 170,
        height: 60,
        x: { align: "center" },
        y: { align: "top", dy: 55 }
    },

    attention: {
        x: { align: "right", dx: 0 },
        y: { align: "top", dy: 10 }
    }
});

cleverapps.overrideStyles(cleverapps.styles.PassTickets, {
    free: {
        x: { align: "center", dx: 0 },
        y: { align: "center", dy: -220 }
    },

    premium: {
        x: { align: "center", dx: 0 },
        y: { align: "center", dy: 160 }
    }
});

cleverapps.overrideStyles(cleverapps.styles.PassTicket, {
    margin: 0,
    height: 714,
    wideMargin: 10,

    free: {
        text: 235
    },

    text: {
        width: 210,
        height: 90
    },

    premium: {
        icon: {
            width: 160,
            height: 220
        }
    },

    button: {
        width: 170,
        height: 60,
        scale: 1.2
    },

    checkmark: {
        x: { align: "center", dx: 80 },
        y: { align: "center", dy: 60 }
    }
});

cleverapps.overrideStyles(cleverapps.styles.PassRulesWindow, {
    firstStage: {
        container: {
            x: { align: "center" },
            y: { align: "center", dy: -17 }
        }
    },

    secondStage: {
        unit: {
            x: 3,
            y: -10,
            scale: 1.3
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.PassLastChanceWindow, {
    offerBadge: {
        x: { align: "right", dx: -150 },
        y: { align: "center", dy: 240 },
        rotation: 15
    },

    premiumOfferBadge: {
        x: { align: "right", dx: 40 },
        y: { align: "center", dy: 240 },
        rotation: 15
    },

    rewards: {
        ticket: {
            x: { align: "right", dx: 59 },
            y: { align: "bottom", dy: -50 },
            scale: 1
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.RuinsView, {
    timer: {
        x: { align: "center", dx: 0 }
    }
});

cleverapps.overrideStyles(cleverapps.styles.RuinsWindow, {
    platesMargin: 40,
    ctaMargin: 30,

    plate: {
        width: 496,
        height: 500,

        units: {
            marginY: 30,
            y: { align: "top", dy: -165 }
        },

        button: {
            y: { align: "bottom", dy: 20 }
        }
    },

    timer: {
        width: 320,
        height: 64
    }
});

cleverapps.overrideStyles(cleverapps.styles.ExpeditionPassWindow, {
    height: 1060,

    "progress": {
        x: { align: "right", dx: -16 },
        y: { align: "bottom", dy: -7 }
    },

    "header": {
        x: { align: "center" },
        y: { align: "top", dy: -55 }
    },

    "tickets": {
        x: { align: "left", dx: 56 },
        y: { align: "bottom", dy: 60 }
    }
});

cleverapps.overrideStyles(cleverapps.styles.SeasonWindow, {
    timer: {
        timerPosition: { x: 0, y: -4 }
    }
});

cleverapps.overrideStyles(cleverapps.styles.ChestTimeMissionWindow, {
    contentPadding: {
        left: 0,
        right: 600
    },

    description: {
        y: { align: "center", dy: -550 }
    },

    pack: {
        x: { align: "center", dx: 900 },
        y: { align: "center", dy: -100 },

        title: {
            x: { align: "center" },
            y: { align: "center", dy: 0 },
            noBg: true,

            text: {
                x: { align: "center" },
                y: { align: "center", dy: 120 },
                width: 400
            }
        },

        energyReward: {
            x: { align: "center" },
            y: { align: "center", dy: -220 },

            icon: {
                scale: 0.8
            }
        },

        buyPackButton: {
            x: { align: "center" },
            y: { align: "center", dy: -350 },
            width: 330,
            height: 110
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.FixedWidthScene, {
    clearColor: new cc.Color(15, 180, 187, 255)
});

cleverapps.overrideStyles(cleverapps.styles.BoostTimeMissionWindow, {
    height: 1000,

    animation: {
        x: { align: "center" },
        y: { align: "center", dy: 230 }
    },

    ribbon: {
        x: { align: "center", dx: 377 },
        y: { align: "center", dy: 370 }
    },

    coinsReward: {
        x: { align: "center", dx: -60 },
        y: { align: "center", dy: -240 }
    },

    buyPackButton: {
        x: { align: "center", dx: 370 },
        y: { align: "center", dy: -254 }
    }
});

cleverapps.overrideStyles(cleverapps.styles.SalePassRulesWindow, {
    firstStage: {
        container: {
            x: { align: "center" },
            y: { align: "center", dy: -25 }
        }
    },
    secondStage: {
        unit: {
            x: 22,
            y: 5
        },

        ground: {
            scale: 1.2
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.CaravanTileView, {
    amount: {
        x: { align: "center" },
        y: { align: "bottom", dy: 140 }
    },

    title: {
        x: { align: "center", dx: 0 },
        y: { align: "center", dy: -25 }
    },

    button: {
        x: { align: "center" },
        y: { align: "bottom", dy: 50 }
    },

    rewardsBlock: {
        withBg: false,

        x: { align: "center" },
        y: { align: "bottom", dy: 140 },

        margin: 10,

        icon: {
            x: { align: "center", dx: -135 },
            y: { align: "center" }
        }
    },

    helpButton: {
        x: { align: "left", dx: 0 },
        y: { align: "top", dy: 5 }
    },

    badge: {
        x: { align: "right", dx: 33 },
        y: { align: "top", dy: 30 },
        scale: 1
    }
});

cleverapps.overrideStyles(cleverapps.styles.BankView, {
    timer: {
        width: 160
    }
});

cleverapps.overrideStyles(cleverapps.styles.LevelUpWindow["default"], {
    width: 1100,
    height: 1000,

    animation: {
        x: { align: "center", dx: 0 },
        y: { align: "center", dy: 130 }
    },

    title: {
        x: { align: "center", dx: 5 },
        y: { align: "center", dy: 320 },
        width: 600,
        height: 0,

        delay: 0.4333,
        duration: 0.2
    },

    value: {
        x: { align: "center", dx: 0 },
        y: { align: "center", dy: -50 },

        delay: 0.6667,
        duration: 0.2
    },

    rewards: {
        x: { align: "center", dx: 0 },
        y: { align: "bottom", dy: 0 },

        delay: 1,
        duration: 0.2
    }
});

cleverapps.overrideStyles(cleverapps.styles.ThirdElementView, {
    timer: {
        width: 154
    },

    plane: {
        x: { align: "center" },
        y: { align: "center", dy: 150 },

        speed: {
            x: -250,
            y: -250
        }
    },

    drop: {
        x: { align: "center" },
        y: { align: "center", dy: 31 }
    }
});

cleverapps.overrideStyles(cleverapps.styles.GeneratorGuideWindow, {
    firstStage: {
        light: {
            y: -15
        }
    },

    secondStage: {
        container: {
            x: { align: "center", dx: 20 },
            y: { align: "center", dy: -10 }
        }
    },

    thirdStage: {
        light: {
            y: -20
        },

        result: {
            y: -30,
            scale: 2
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.BaseClanWindow, {
    banner: {
        x: { align: "left", dx: -226 },
        y: { align: "top", dy: 28 },

        emblem: {
            x: { align: "center", dx: -8 },
            y: { align: "center", dy: 38 }
        },

        title: {
            x: { align: "center", dx: 5 },
            y: { align: "top", dy: 50 },
            width: 480,

            text: {
                x: { align: "center", dx: 0 },
                y: { align: "center", dy: 29 },
                width: 350
            }
        }
    },

    tabs: {
        y: { align: "bottom", dy: 15 },
        height: 140,
        margin: 10,
        padding: {
            left: 20,
            right: 35
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.ClansJoinTab, {
    padding: {
        x: -20,
        top: 85,
        bottom: 95
    },

    table: {
        padding: {
            left: -10,
            right: 0,
            top: 30,
            bottom: 35
        },
        innerPadding: 20
    },

    createClanButton: {
        x: { align: "right", dx: -20 },
        y: { align: "top", dy: 17 }
    },

    search: {
        bg: {
            x: { align: "left", dx: 95 },
            y: { align: "top", dy: 20 }
        },

        button: {
            x: { align: "left", dx: 20 },
            y: { align: "top", dy: 28 }
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.ClansTopTab, {
    padding: {
        x: -20,
        top: -10,
        bottom: 0
    },

    table: {
        padding: {
            left: -10,
            right: 0,
            top: 30,
            bottom: 35
        },
        innerPadding: 20
    }
});

cleverapps.overrideStyles(cleverapps.styles.ClansInfoTab, {
    padding: {
        x: -20,
        top: -10,
        bottom: 100
    },

    table: {
        padding: {
            left: -10,
            right: 0,
            top: 30,
            bottom: 35
        },
        innerPadding: 20
    },

    requiredLevel: {
        width: 240,
        height: 88,

        levelBg: {
            x: { align: "right", dx: 45 },
            y: { align: "center", dy: 0 },
            height: 100
        },

        text: {
            width: 160,
            height: 80,
            x: { align: "center", dx: -20 },
            y: { align: "center" }
        },

        amount: {
            x: { align: "center", dx: -1 },
            y: { align: "center", dy: 5 }
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.ClansTopRow, {
    place: {
        background: {
            width: 70,
            height: 70
        }
    },

    data: {
        offsetRight: 10
    }
});

cleverapps.overrideStyles(cleverapps.styles.ClansInfoRow, {
    text: {
        x: { align: "center", dx: -1 },
        y: { align: "center", dy: 5 }
    }
});

cleverapps.overrideStyles(cleverapps.styles.ClansCupWindow, {
    table: {
        x: { align: "center", dx: 0 },
        y: { align: "top", dy: -30 },
        height: 820,
        innerPadding: 20,

        padding: {
            x: 0,
            y: 30
        }
    },

    finishText: {
        x: { align: "center", dx: 0 },
        y: { align: "bottom", dy: 10 }
    },

    tabs: {
        x: { align: "left", dx: 70 },
        y: { align: "top", dy: 69 },
        padding: {
            top: 5,
            bottom: 15
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.ClanMessageRow, {
    teammateHelp: {
        offsetX: 63
    }
});

cleverapps.overrideStyles(cleverapps.styles.AddClanWindow, {
    emblem: {
        underline: {
            x: { align: "center" },
            y: { align: "center", dy: -25 },
            height: 2
        },

        icon: {
            x: { align: "center", dx: 0 },
            y: { align: "center", dy: 15 }
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.ClansChatTab, {
    chatBg: {
        width: 1320,
        height: 850,
        x: { align: "center" },
        y: { align: "top", dy: 10 }
    }
});

cleverapps.overrideStyles(cleverapps.styles.UI.CheckBox, {
    label: {
        offsetY: -8
    }
});

cleverapps.overrideStyles(cleverapps.styles.ThirdElementWindow, {
    infoBlock: {
        timer: {
            x: { align: "left", dx: 50 },
            y: { align: "center", dy: 10 }
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.PackOfferComponent, {
    tap: {
        x: { align: "left", dx: -380 },
        y: { align: "center", dy: 410 },
        zIndex: 3
    },
    pack: {
        offsetY: 205,
        offsetX: 190,
        title: {
            x: { align: "center", dx: 175 },
            y: { align: "center", dy: -125 },
            width: 200,
            font: cleverapps.styles.FONTS.LIGHT_PACK_BUTTON_TEXT
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.DeleteAccountWindow, {
    person: "worker"
});

cleverapps.overrideStyles(cleverapps.styles.SubscriptionWindow, {
    width: 1200,
    margin: 45
});

cleverapps.overrideStyles(cleverapps.styles.TileBadge, {
    tooltip: {
        position: {
            x: { align: "center", dx: 0 },
            y: { align: "top", dy: 60 }
        }
    }
});

cleverapps.styles.ExpeditionFeastWindow = {
    decor: {
        x: { align: "center", dx: 0 },
        y: { align: "center", dy: 24 }
    }
};

cleverapps.overrideStyles(cleverapps.styles.LoaderSceneBackground, {
    ageRestriction: {
        x: { align: "left", dx: 100 },
        y: { align: "top", dy: -100 }
    },

    logoAnimation: {
        pos: {
            factorX: 0.52,
            factorY: 0.85
        },
        posVert: {
            factorX: 0.5,
            factorY: 0.9
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.LandMarkDonorWindow, {
    content: {
        x: { align: "center" },
        y: { align: "center", dy: 15 }
    }
});

cleverapps.overrideStyles(cleverapps.styles.SideBarSlot, {
    wideModePadding: [
        {
            top: 156,
            horizontal: 20
        },
        {
            top: 190,
            horizontal: 40
        },
        {
            top: 190,
            horizontal: 50
        }
    ]
});